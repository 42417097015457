import { Typography } from "@mui/material";
import React from "react";
import tagline from "../../../assets/homePageAssets/homePageTagLine.svg";
import abtImg1 from "../../../assets/homePageAssets/abtImg2.png";
import abtImg2 from "../../../assets/homePageAssets/abtImg1.png";

const AboutVisionStream = () => {
  return (
    <div className="container mx-auto px-4 md:px-0 py-[90px]">
      <div className="grid grid-cols-12 gap-6 md:gap-12">
        <div className="col-span-12 md:col-span-3 flex items-center flex-col justify-between">
          <Typography
            fontFamily={"raleway"}
            variant="h3"
            textAlign={{ xs: "center", md: "left" }}
          >
            What is Vision Stream
          </Typography>

          <img className="p-12 md:p-0" src={tagline} />
        </div>
        <div className="col-span-12 md:col-span-9">
          <Typography
            variant="body1"
            fontWeight={"300"}
            fontFamily={"poppins"}
            lineHeight={"1.6"}
            letterSpacing={"1px"}
          >
            Dynamic Consulting Firm: Vision Stream Consultancy stands out as a
            versatile and innovative consulting partner committed to turning
            your SME’s challenges into opportunities for growth and efficiency.
            <br /> <br /> Global Presence: With operations in Canada and India,
            we offer a global perspective combined with local insights across
            various industry segments, ensuring your business stays ahead.
          </Typography>

          <div className="flex items-center justify-between py-[70px] *:px-4">
            <img className="w-6/12" src={abtImg2} />
            <img src={abtImg1} className="w-6/12" />
          </div>
          <Typography
            variant="body1"
            fontWeight={"300"}
            fontFamily={"poppins"}
            lineHeight={"1.6"}
            letterSpacing={"1px"}
          >
            Specialized Services: From business operations support to real
            estate consulting and strategic business expansion, our
            comprehensive services are designed to cater to your every need,
            propelling your enterprise towards its goals.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AboutVisionStream;
