import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bgImg from "../../../assets/ourTeamAssets/ourTeamBg.png";
import { appInternalRoutes } from "../../../data/constants/appInternalRoutes";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <Box
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 2rem",
        }}
      >
        <Box className="container mx-auto">
          <Box
            className="space-y-8"
            sx={{
              width: { xs: "100%", md: "50%" },
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontSize={"50px"}
              className="text-white"
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              We got the best peeps around the globe!
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              className="text-white"
              variant="body1"
              textAlign={{ xs: "center", md: "left" }}
            >
              Our Team of experts is dedicated to assisting you and meeting all
              your project needs. We're here to provide tailored solutions that
              align perfectly with your requirements.
            </Typography>

            <button
              onClick={() => navigate(appInternalRoutes.CONTACT_US_PAGE)}
              className="bg-white text-black p-2 rounded-lg border border-black hover:border-white hover:bg-black hover:text-white mx-auto md:mx-auto lg:mx-0"
            >
              Let's catch up
            </button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HeroSection;
