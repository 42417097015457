import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { appInternalRoutes } from "./data/constants/appInternalRoutes";
import { AboutUs, ContactUs, HomePage, OurTeam } from "./features";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={appInternalRoutes.HOME_PAGE} element={<HomePage />} />
        <Route
          path={appInternalRoutes.CONTACT_US_PAGE}
          element={<ContactUs />}
        />
        <Route path={appInternalRoutes.ABOUT_US_PAGE} element={<AboutUs />} />
        <Route path={appInternalRoutes.OUR_TEAM} element={<OurTeam />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
