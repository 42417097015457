import { Typography } from "@mui/material";
import React from "react";
import founderImg from "../../../assets/ourTeamAssets/kamalMainPhoto.jpeg";
import FounderJourney from "./FounderJourney";

const FoundersDetail = () => {
  return (
    <div className="bg-black text-white text-center md:text-left">
      <div className="mb-20 sm:mb-36 mx-0 sm:mx-36 px-4 sm:px-0 space-y-20 py-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12 lg:col-span-4">
            <div className="relative h-[335px] max-w-[376px] rounded-2xl flex items-center justify-center">
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-t from-[#000000b8] to-transparent rounded-3xl"></div>
              <img
                src={founderImg}
                className="h-full w-full object-contain rounded-3xl bg-[#fff]"
              />
              <div className="text-white absolute bottom-0 p-8">
                <h4 className="font-semibold text-2xl sm:text-4xl my-2">
                  Kamal Tomar
                </h4>
                <p className="text-sm sm:text-xl">Founder & CEO</p>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-12 lg:col-span-8">
            <p className="text-[50px] font-light font-railway">
              Brain Behind Vision Stream
            </p>

            <Typography fontFamily={"poppins"} fontWeight={"200"}>
              Kamal Singh Tomar, the visionary founder of Vision Stream
              Consultancy, is a distinguished business professional specializing
              in Business Operations Efficiency, Growth, and Franchisee
              Development. <br /> <br /> As the CEO of AN Global Consulting
              Canada and VP Global Franchise Operations at Save Max
              International, he demonstrates exceptional leadership and
              strategic acumen in Franchise Development, Operational efficiency,
              and successful brand expansion. With a proven track record, Kamal
              has onboarded over 200 franchisees across FMCG, Education, Real
              Estate, and Banking.
            </Typography>
          </div>
        </div>
        <Typography fontFamily={"poppins"} fontWeight={"200"}>
          His international expertise spans markets like India, Canada, USA, and
          Australia. A true industry heavy weight, he consistently achieves
          remarkable milestones, introducing pioneering business models,
          securing top revenue contributions, and fostering high-performance
          cultures. <br />
          <br /> Holding an MBA from Mumbai University and a B. Com from Jiwaji
          University, Kamal combines academic excellence with cross-cultural
          communication proficiency in English, Hindi, Punjabi, Marathi, and
          Gujarati. In summary, he is a force in sales and business development,
          solidifying his standing as a prominent leader in today’s competitive
          business landscape.
        </Typography>

        <FounderJourney />
      </div>
    </div>
  );
};

export default FoundersDetail;
