import { Typography } from "@mui/material";
import React from "react";

const SwitchSlides = ({
  onNextClick,
  onPreviousClick,
  cardClassNames = "",
  theme = "black",
}) => {
  return (
    <div className={cardClassNames}>
      <div className="flex items-center justify-between min-w-[220px]">
        <Typography
          letterSpacing={4}
          fontFamily={"poppins"}
          fontWeight={"200"}
          onClick={onPreviousClick}
          style={{ cursor: "pointer", color: theme }}
          className="pr-2"
        >
          Previous
        </Typography>
        <div
          className={`h-[1px] w-[120px] ${
            theme === "white" ? "bg-white" : "bg-black"
          }`}
        />
        <Typography
          letterSpacing={4}
          fontFamily={"poppins"}
          fontWeight={"200"}
          onClick={onNextClick}
          style={{ cursor: "pointer", color: theme }}
          className="px-2"
        >
          Next
        </Typography>
      </div>
    </div>
  );
};

export default SwitchSlides;
