import { ArrowRightAlt, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import company_bg from "../../../../assets/homePageAssets/company_option_bg.png";
import insights_bg from "../../../../assets/homePageAssets/insights_header_bg.png";
import { appInternalRoutes } from "../../../../data/constants/appInternalRoutes";
import { vsServicesData } from "../../../../data/constants/vsServicesData";
import { scrollSectionInView } from "../../../../utils/functions";

export const ServicesMenuOption = ({
  setSelectedMenuItem,
  setOpenMobileMenu,
}) => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-3 md:p-9 space-y-8">
      <div className="flex items-center justify-between">
        <Typography
          className="hidden md:block"
          variant={"h5"}
          fontFamily={"raleway"}
        >
          Let us know what are you looking for
        </Typography>

        <span
          className="flex md:hidden items-center space-x-2"
          onClick={() => {
            setSelectedMenuItem();
          }}
        >
          <ChevronLeft />
          <Typography
            className=""
            variant={"h5"}
            fontWeight={"800"}
            fontFamily={"raleway"}
          >
            Services
          </Typography>
        </span>

        <Typography
          onClick={() => {
            navigate(appInternalRoutes.HOME_PAGE);
            scrollSectionInView("homepage_services");
            setSelectedMenuItem();
            setOpenMobileMenu();
          }}
          variant="h5"
          className="cursor-pointer"
          fontFamily={"raleway"}
        >
          VIEW ALL <ArrowRightAlt />
        </Typography>
      </div>

      <div className="grid grid-cols-12 gap-6 overflow-scroll">
        {vsServicesData.map((item) => {
          return (
            <div className="col-span-12 md:col-span-4">
              <HeaderServiceCard
                title={item?.title}
                description={item?.content}
                setSelectedMenuItem={setSelectedMenuItem}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const CompanyMenuOption = ({ setSelectedMenuItem }) => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto p-3 md:p-9 space-y-4 md:space-y-8 h-screen overflow-scroll">
      <div className="flex items-center justify-between">
        <Typography
          className="hidden md:block"
          variant="h5"
          fontFamily={"raleway"}
        >
          Let us know what you want to know about us
        </Typography>

        <span
          className="flex md:hidden items-center space-x-2"
          onClick={() => {
            setSelectedMenuItem();
          }}
        >
          <ChevronLeft />
          <Typography
            className=""
            variant={"h5"}
            fontWeight={"800"}
            fontFamily={"raleway"}
          >
            Company
          </Typography>
        </span>
      </div>

      <div className="grid grid-cols-12 gap-1 md:gap-6">
        <div
          className="space-y-4 bg-[#f4f4f4] p-3 md:p-5 rounded-2xl hover:bg-[#23272A] hover:text-white col-span-12 md:col-span-4 cursor-pointer"
          onClick={() => navigate(appInternalRoutes.ABOUT_US_PAGE)}
        >
          <Typography fontFamily={"raleway"} variant="h6">
            About Us <ChevronRight className="block md:hidden" />
          </Typography>

          <Typography fontFamily={"raleway"} variant="body1">
            Vision Stream Global Consultancy leads the way in business growth
            and expansion consulting, emphasizing the refinement of business
            operations and the execution of strategic initiatives.
          </Typography>
        </div>

        <div
          className="space-y-4 bg-[#f4f4f4] p-3 md:p-5 rounded-2xl hover:bg-[#23272A] hover:text-white col-span-12 md:col-span-4 cursor-pointer"
          onClick={() => navigate(appInternalRoutes.CONTACT_US_PAGE)}
        >
          <Typography fontFamily={"raleway"} variant="h6">
            Contact Us <ChevronRight className="block md:hidden" />
          </Typography>

          <Typography fontFamily={"raleway"} variant="body1">
            We appreciate your feedback, inquiries, and suggestions. Whether you
            have questions about our products or services, need help with an
            order, or want to share your thoughts with us, we're available to
            assist you.
          </Typography>
        </div>

        {/* <div
          className="space-y-4 bg-[#f4f4f4] p-5 rounded-2xl hover:bg-[#23272A] hover:text-white col-span-12 md:col-span-4 cursor-pointer"
          onClick={() => {
            navigate(appInternalRoutes.HOME_PAGE);
            scrollSectionInView("our_partner_home_page");
            setSelectedMenuItem();
          }}
        >
          <Typography fontFamily={"raleway"} variant="h6">
            Be Our Partner <ChevronRight className="block md:hidden" />
          </Typography>

          <Typography fontFamily={"raleway"} variant="body1">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry’s standard dummy text
            ever since the 1500s.
          </Typography>
        </div> */}
      </div>

      <div
        onClick={() => {
          navigate(appInternalRoutes.OUR_TEAM);
        }}
        className="relative"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent rounded-3xl opacity-100" />
        <div className="absolute w-full md:w-5/12 top-[50%] translate-y-[-50%] p-3 md:p-8 space-y-3">
          <Typography
            fontSize={"2.5rem"}
            color={"white"}
            variant="h5"
            fontFamily={"raleway"}
          >
            Meet Our Team
          </Typography>
          <Typography
            fontSize={"12px"}
            fontFamily={"raleway"}
            variant="body1"
            color={"white"}
          >
            Our Team of experts is dedicated to assisting you and meeting all
            your project needs. We're here to provide tailored solutions that
            align perfectly with your requirements.
          </Typography>

          <Typography
            fontFamily={"raleway"}
            fontWeight={"bold"}
            color={"white"}
            letterSpacing={"2px"}
            className="cursor-pointer"
          >
            Click here to explore <ArrowRightAlt />
          </Typography>
        </div>

        <img
          src={company_bg}
          className="w-full min-h-[200px] object-cover rounded-3xl"
        />
      </div>
    </div>
  );
};

export const InsightsMenuOption = ({ setSelectedMenuItem }) => {
  return (
    <div className="container mx-auto p-9 space-y-8">
      <div className="flex items-center justify-between">
        <Typography
          className="hidden md:block"
          variant="h5"
          fontFamily={"raleway"}
        >
          Get all the latest stories here and stay updated
        </Typography>

        <span
          className="flex md:hidden items-center space-x-2"
          onClick={() => {
            setSelectedMenuItem();
          }}
        >
          <ChevronLeft />
          <Typography
            className=""
            variant={"h5"}
            fontWeight={"800"}
            fontFamily={"raleway"}
          >
            Insights
          </Typography>
        </span>
      </div>

      <div className="grid grid-cols-12 gap-6 md:gap-12">
        <div className="col-span-12 md:col-span-4">
          <div className="grid grid-cols-12 gap-4 md:gap-8">
            <div className="space-y-4 bg-[#f4f4f4] px-5 py-11 rounded-2xl hover:bg-[#23272A] hover:text-white col-span-12">
              <Typography fontFamily={"raleway"} variant="h6">
                Blogs
              </Typography>

              <Typography fontFamily={"raleway"} variant="body1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s.
              </Typography>
            </div>

            <div className="space-y-4 bg-[#f4f4f4] px-5 py-11 rounded-2xl hover:bg-[#23272A] hover:text-white col-span-12">
              <Typography fontFamily={"raleway"} variant="h6">
                Latest Update
              </Typography>

              <Typography fontFamily={"raleway"} variant="body1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s.
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-8">
          <div
            style={{
              backgroundImage: `url(${insights_bg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-full p-4 md:p-12 rounded-3xl"
          >
            <Typography
              sx={{
                maxWidth: { xs: "100%", md: "50%" },
                fontSize: { xs: "20px" },
                md: "40px",
              }}
              color={"white"}
              variant="h3"
              fontFamily={"raleway"}
            >
              Want to know more about us? Don’t worry, we got your back…
            </Typography>

            <Typography
              sx={{
                maxWidth: { xs: "100%", md: "50%" },
                pt: { xs: "20px", md: "40px" },
              }}
              color={"white"}
              fontWeight={"800"}
              variant="body1"
              fontFamily={"raleway"}
            >
              Click here to explore <ArrowRightAlt />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeaderServiceCard = ({ title, description, setSelectedMenuItem }) => {
  const navigate = useNavigate();

  return (
    <div
      className="space-y-4 bg-[#f4f4f4] p-5 rounded-2xl hover:bg-[#23272A] hover:text-white min-h-full"
      onClick={() => {
        navigate(appInternalRoutes.CONTACT_US_PAGE);
        setSelectedMenuItem();
      }}
    >
      <Typography fontFamily={"raleway"} variant="h6">
        {title}
      </Typography>

      <Typography
        className="hidden md:block"
        fontFamily={"raleway"}
        variant="body1"
      >
        {description}
      </Typography>
    </div>
  );
};
