import { Box, Typography } from "@mui/material";
import React from "react";
import bgImg from "../../../assets/contactPageAssets/contact_bg_img.png";
import { scrollSectionInView } from "../../../utils/functions";

const PageHeroSection = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 2rem",
        }}
      >
        <Box className="container mx-auto">
          <Box
            className="space-y-8"
            sx={{
              width: { xs: "100%", md: "45%" },
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontSize={"50px"}
              className="text-white"
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Hey there! Want to talk to us? <br />
              Fell free to reach out to us.
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              textAlign={{ xs: "center", md: "left" }}
              className="text-white"
              variant="body1"
            >
              We appreciate your feedback, inquiries, and suggestions. Whether
              you have questions about our products or services, need help with
              an order, or want to share your thoughts with us, we're available
              to assist you.
            </Typography>

            <button
              onClick={() => scrollSectionInView("contact_page_contact_form")}
              className="bg-white p-2 rounded-lg text-black border border-white hover:bg-transparent hover:text-white mx-auto md:mx-0"
            >
              Let's catch up
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageHeroSection;
