import React from "react";
import aboutUsContentImages from "../../../assets/aboutUsPageAssets/aboutUsImagesGrid.png";
import { Grid, Typography } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";

const AboutContent = () => {
  return (
    <div className="container mx-auto px-4 md:px-0 pt-[70%] md:pt-[20%]">
      <Grid container spacing={4} className="about-us">
        <Grid item xs={12} md={6} className="about-us-images hidden md:block">
          <img
            src={aboutUsContentImages}
            alt="Image 1"
            className="about-us-image"
          />
        </Grid>
        <Grid item xs={12} md={6} className="about-us-content">
          <Typography
            fontFamily={"raleway"}
            variant="h3"
            sx={{ mt: "20px" }}
            className="about-us-title"
          >
            All you want to know <br /> about Vision Stream?
          </Typography>
          <Typography
            fontFamily={"poppins"}
            lineHeight={"1.8"}
            letterSpacing={"1.2px"}
            sx={{ mt: "20px" }}
            variant="body1"
            fontWeight={"300"}
            className="about-us-description"
          >
            Our complete business support framework revolves around these five
            core principles.
          </Typography>

          <div className="mt-[20px] grid grid-cols-12 gap-4 md:gap-0">
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-4">
                <span className="flex items-center space-x-4">
                  <CheckOutlined
                    sx={{
                      background: "#f4f4f4",
                      p: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>Sustainability</p>
                </span>

                <span className="flex items-center space-x-4">
                  <CheckOutlined
                    sx={{
                      background: "#f4f4f4",
                      p: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>Measurable Impacts</p>
                </span>

                <span className="flex items-center space-x-4">
                  <CheckOutlined
                    sx={{
                      background: "#f4f4f4",
                      p: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>Empowerment</p>
                </span>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-4">
                <span className="flex items-center space-x-4">
                  <CheckOutlined
                    sx={{
                      background: "#f4f4f4",
                      p: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>Transparency & Integrity</p>
                </span>

                <span className="flex items-center space-x-4">
                  <CheckOutlined
                    sx={{
                      background: "#f4f4f4",
                      p: "4px",
                      borderRadius: "50%",
                    }}
                  />
                  <p>Flexibility & Adaptability</p>
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutContent;
