import { Typography } from "@mui/material";
import React, { useRef } from "react";
import SwitchSlides from "../../../components/UI/SwitchSlides";
import { vsScopeData } from "../../../data/constants/vsScopeData";
import Slider from "react-slick";
import useScreenSize from "../../../utils/hooks/useScreenSize";

const VSScope = () => {
  const sliderRef = useRef(null);

  let settings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    useTransform: true,
    cssEase: "ease-out",

    infinite: true,
  };

  const onNextClick = () => {
    sliderRef.current.slickNext();
  };

  const onPreviousClick = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="bg-black">
      <div className="container mx-auto py-14 px-4 md:px-0">
        <Typography
          className="text-white"
          variant="h3"
          fontFamily={"raleway"}
          gutterBottom
        >
          We have our hands in…
        </Typography>

        <div className="mt-8">
          <Slider ref={sliderRef} {...settings}>
            {vsScopeData.map((item, index) => (
              <StructureCard
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                {...item}
                key={index}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default VSScope;

const StructureCard = ({
  title,
  boldText,
  description,
  imgSrc,
  onNextClick,
  onPreviousClick,
}) => {
  const width = useScreenSize();

  return (
    <>
      <div className="bg-white rounded-2xl flex items-center justify-center py-14">
        <div className="flex flex-col md:flex-row items-center justify-between max-w-[80%]">
          <div className="md:max-w-[45%]">
            <Typography
              letterSpacing={"4px"}
              fontFamily={"raleway"}
              variant="h4"
            >
              {title} <span className="font-bold">{boldText}</span>
            </Typography>

            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              sx={{ mt: "40px" }}
              variant="body1"
            >
              {description}
            </Typography>

            {width > 768 && (
              <SwitchSlides
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                cardClassNames="max-w-[50%] mt-[76px]"
              />
            )}
          </div>
          <img className="md:w-[30%] rounded-3xl mt-4 md:mt-0" src={imgSrc} />

          {width < 768 && (
            <SwitchSlides
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              cardClassNames="md:max-w-[50%] mt-[76px]"
            />
          )}
        </div>
      </div>
    </>
  );
};
