import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bgImg from "../../../assets/aboutUsPageAssets/aboutUsCtaBg.png";

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <Box
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          backgroundRepeat: "no-repeat",
          padding: "0 2rem",
        }}
      >
        <Box className="container mx-auto">
          <Box
            className="space-y-8"
            sx={{
              width: { xs: "100%", md: "45%" },
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              px: { xs: "0px", md: "28px" },
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontSize={"50px"}
              className="text-white"
              variant="h2"
              gutterBottom
            >
              Looking for custom pricing?
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              className="text-white"
              variant="body1"
            >
              For project pricing inquiries, reach out to us so we can offer you
              competitive rates paired with top-notch service. Let’s connect and
              ensure you get the best value for your project.
            </Typography>

            <button
              onClick={() => navigate("/contact-us")}
              className="bg-white text-black p-2 rounded-lg border border-white hover:bg-transparent hover:text-white"
            >
              Book Consultation
            </button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CallToAction;
