import img_4 from "../../assets/homePageAssets/business_expansion.png";
import img_1 from "../../assets/homePageAssets/business_migration.png";
import img_3 from "../../assets/homePageAssets/business_opretion_effeciency.png";
import img_7 from "../../assets/homePageAssets/forign_market_relocation_service.png";
import img_2 from "../../assets/homePageAssets/frenchise_development.png";
import img_5 from "../../assets/homePageAssets/merger_and_acquisition.png";
import img_6 from "../../assets/homePageAssets/product_launch.png";

export const vsServicesData = [
  {
    id: 1,
    title: "Business Migration",
    content:
      "Discover our expertise in facilitating corporate migration. We specialize in seamless business relocation services, ensuring a smooth transition for your company.",
    img: img_1,
  },

  {
    id: 2,
    title: "Franchise Development",
    content:
      "Explore our suite of services dedicated to franchise expansion. Delve into our specialized offerings designed to foster the growth of your franchise network.",
    img: img_2,
  },

  {
    id: 3,
    title: "Mergers And Acquisitions",
    content:
      "Navigate the realm of mergers and acquisitions with our specialized services. Explore our expertise in facilitating strategic business combinations and acquisitions.",
    img: img_3,
  },
  {
    id: 4,
    title: "Business Operation Efficiency",
    content:
      "Enhance the efficiency of your business operations with our specialized services. Explore solutions designed to streamline and optimize your day-to-day processes.",
    img: img_4,
  },
  {
    id: 5,
    title: "Business Expansion",
    content:
      "Unlock growth opportunities for your business through our comprehensive expansion services.",
    img: img_5,
  },
  {
    id: 6,
    title: "Product Launch",
    content:
      "Embark on a successful product introduction with our specialized launch services. Explore our comprehensive offerings designed to guide and optimize the unveiling of your new product.",
    img: img_6,
  },

  {
    id: 7,
    title: "Foreign Market Relocation Service",
    content:
      "Discover our specialized service for relocating your business to foreign markets",
    img: img_7,
  },
];
