import { Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useState } from "react";
import useScreenSize from "../../../utils/hooks/useScreenSize";

const CalanderSection = () => {
  const [selectedDateObject, setSelectedDateObject] = useState({
    year: "",
    date: "",
    month: "",
  });
  const width = useScreenSize();

  return (
    <div className="container mx-auto">
      <div className="bg-black text-white my-[4%] py-[4%] rounded-3xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-6 flex items-center justify-center w-full">
            <Typography
              variant={width > 767 ? "h1" : "h2"}
              fontWeight={"900"}
              sx={{ display: "inline-block", textAlign: "center" }}
            >
              Book a free consultation
            </Typography>
          </div>
          <div className="col-span-12 md:col-span-6 flex items-center justify-center">
            <div className="md:w-[474px] rounded-3xl bg-white text-black">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  onChange={(value) => {
                    const year = dayjs(value).toDate().getFullYear();
                    const month = dayjs(value).toDate().getMonth();
                    const date = dayjs(value).toDate().getDate();
                    setSelectedDateObject({
                      ...selectedDateObject,
                      date,
                      month,
                      year,
                    });
                  }}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                />
                <button
                  className="bg-black text-white p-4 m-2 rounded-2xl float-right"
                  onClick={() => {
                    window.open(
                      `https://calendly.com/kamaltomar/60minutes?month=${selectedDateObject.year}-${selectedDateObject.month}&date=${selectedDateObject.year}-${selectedDateObject.month}-${selectedDateObject.date}`,
                      "_blank"
                    );
                  }}
                >
                  Continue
                </button>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalanderSection;
