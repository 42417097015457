import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import contactUsImg from "../../../assets/homePageAssets/contactUsImg.png";
import { FormSubmissionThankYouModal } from "../../../components";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contactContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
  },
  submitButton: {
    width: "fit-content",
    marginTop: theme.spacing(2),
    background: "black",
    color: "white",

    "&:hover": {
      background: "white",
      color: "black",
      border: "1px solid black",
    },
  },
}));

const validationSchema = Yup.object({
  fullName: Yup.string().required("Full name is required"),
  businessEmail: Yup.string()
    .email("Invalid email")
    .required("Business email is required"),
  companyName: Yup.string().required("Company name is required"),
  requirements: Yup.string(),
  phoneNumber: Yup.number().required("Phone number is required"),
});

const ContactUs = () => {
  const classes = useStyles();
  const [openThankUserModal, setOpenThankUserModal] = useState(false);

  return (
    <>
      <div
        id="contact_page_contact_form"
        className={`${classes.contactContainer} container mx-auto px-4 md:px-0 md:*:w-6/12 py-[75px] flex-col md:flex-row `}
      >
        <div>
          <Typography fontWeight={"200"} variant="h3" fontFamily={"raleway"}>
            Connect with us
          </Typography>

          <Typography
            fontWeight={"200"}
            variant="body1"
            fontFamily={"raleway"}
            className="pt-6 pb-14"
          >
            Welcome to Vision Stream Global Consultancy. Your partner in
            providing exceptional consultancy services tailored to your needs.
          </Typography>
          <Formik
            initialValues={{
              fullName: "",
              businessEmail: "",
              companyName: "",
              requirements: "",
              phoneNumber: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setOpenThankUserModal(true);
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form className={classes.form}>
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-12 md:col-span-6">
                    <Field
                      as={TextField}
                      name="fullName"
                      label="Full Name"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.fullName)}
                      helperText={errors.fullName}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Field
                      as={TextField}
                      name="businessEmail"
                      label="Business Email"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.businessEmail)}
                      helperText={errors.businessEmail}
                    />
                  </div>
                </div>

                <Field
                  as={TextField}
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber}
                />

                <Field
                  as={TextField}
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.companyName)}
                  helperText={errors.companyName}
                />
                <Field
                  as={TextField}
                  name="requirements"
                  label="Describe Your Requirements (Optional)"
                  variant="outlined"
                  multiline
                  rows={4}
                />
                <Button
                  type="submit"
                  variant="outlined"
                  color="default"
                  className={classes.submitButton}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>

        <div className={`${classes.imageContainer} order-first md:order-last`}>
          <img src={contactUsImg} alt="Contact Us" />
        </div>
      </div>

      <FormSubmissionThankYouModal
        open={openThankUserModal}
        handleClose={() => setOpenThankUserModal(false)}
      />
    </>
  );
};

export default ContactUs;
