import bannerImgOne from "../../assets/homePageAssets/homePageState1.mp4";
import bannerImgTwo from "../../assets/homePageAssets/homePageState2.mp4";
import bannerImgThree from "../../assets/homePageAssets/homePageState3.mp4";

export const homePageBannerData = [
  {
    title: "Let us know what you are looking for",
    description:
      "Share your needs, and we will assist in meeting them. Whether you are seeking information, answers, or tailored solutions, our team is here to support you.",
    buttonText: "Let’s Catch Up",
    bgImg: bannerImgOne,
  },

  {
    title: "Let us know what you want to know about us",
    description:
      "Inform us of your inquiries about our organization. We are eager to provide you with comprehensive details that match your curiosity.",
    buttonText: "Know Us",
    bgImg: bannerImgTwo,
  },

  {
    title: "Get to know all the latest business insights here",
    description:
      "Explore the most recent business insights right here and stay informed with the latest updates.",
    buttonText: "Explore More",
    bgImg: bannerImgThree,
  },
];
