import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bgImg from "../../../assets/aboutUsPageAssets/aboutUsBg.png";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="relative">
      <Box
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 2rem",
        }}
      >
        <Box className="container mx-auto">
          <Box
            className="space-y-8"
            sx={{
              width: { xs: "100%", md: "45%" },
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontSize={"50px"}
              className="text-white"
              variant="h2"
              gutterBottom
              textAlign={{ xs: "center", md: "left" }}
            >
              Let us know what you want to know about us
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              className="text-white"
              variant="body1"
              textAlign={{ xs: "center", md: "left" }}
            >
              Vision Stream Global Consultancy leads the way in business growth
              and expansion consulting, emphasizing the refinement of business
              operations and the execution of strategic initiatives.
            </Typography>

            <button
              onClick={() => navigate("/contact-us")}
              className="bg-white text-black p-2 rounded-lg border border-black hover:border-white hover:bg-black hover:text-white mx-auto md:mx-0"
            >
              Let's catch up
            </button>
          </Box>
        </Box>
      </Box>

      <Box className="bg-black md:max-w-[25%] absolute md:right-[6%] p-6 rounded-3xl bottom-[-20%]">
        <Box>
          <Typography
            className="text-white"
            variant="h4"
            fontFamily={"raleway"}
          >
            It’s not about ideas, it’s about making ideas happen.
          </Typography>

          <Typography
            variant="body2"
            className="text-white py-4 text-right"
            fontFamily={"raleway"}
          >
            - Scott Belsky, co-founder of Behance
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default HeroSection;
