import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Typography } from "@mui/material";
import React from "react";
const Location = () => {
  return (
    <div>
      <div class="relative h-screen w-full overflow-hidden">
        <iframe
          class="absolute inset-0 w-full h-full bg-white"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.787781653073!2d-79.66391652353009!3d43.83949804036118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b23f28fb9f73b%3A0xb3a38d1601631c29!2sBellefond%20St%2C%20Vaughan%2C%20ON%20L4H%203N5%2C%20Canada!5e0!3m2!1sen!2sin!4v1713109398127!5m2!1sen!2sin"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <Card className="absolute top-[30%] md:top-[50%] translate-y-[-50%] md:left-10 bg-white m-4 md:m-0 p-4 shadow-lg rounded-lg max-w-[400px] border border-black">
          <CardContent>
            <Typography variant="h5" fontFamily={"raleway"}>
              Bellefond St Vaughan, ON L4H 5C5
            </Typography>
            <Typography
              sx={{ pt: "20px" }}
              fontFamily={"poppins"}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              ON, Canada
            </Typography>

            <div className="flex items-center mt-8 space-x-2">
              <EmailIcon />
              <Typography
                fontFamily="raleway"
                variant="body2"
                color="textSecondary"
                component="p"
                className="ml-2"
              >
                info@visionstreamconsultancy.com
              </Typography>
            </div>
            <div className="flex items-center mt-2 space-x-2">
              <LocalPhoneIcon />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className="ml-2"
              >
                +1 (416) 333-8747
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Location;
