import energyImg from "../../assets/homePageAssets/energy.png";
import education from "../../assets/homePageAssets/education.png";
import oilAndGas from "../../assets/homePageAssets/oilAndGas.png";
import qsr from "../../assets/homePageAssets/qsr.png";
import solar from "../../assets/homePageAssets/solar.png";
import fmcg from "../../assets/homePageAssets/fmcg.png";
import healthcare from "../../assets/homePageAssets/healthcare.png";

export const vsScopeData = [
  {
    imgSrc: oilAndGas,
    title: "We are working with industries like ",
    boldText: "Oil and Gas",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
  {
    imgSrc: education,
    title: "We are working with industries like ",
    boldText: "Education",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
  {
    imgSrc: solar,
    title: "We are working with industries like ",
    boldText: "Solar",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
  {
    imgSrc: fmcg,
    title: "We are working with industries like ",
    boldText: "FMCG",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
  {
    imgSrc: healthcare,
    title: "We are working with industries like ",
    boldText: "Healthcare",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
  {
    imgSrc: qsr,
    title: "We are working with industries like ",
    boldText: "QSR",
    description:
      "We are actively involved in various industries, spanning a diverse range of sectors. Explore the sectors where our expertise is applied, contributing to our broad and dynamic portfolio.",
  },
];
