import { Box, Typography } from "@mui/material";
import React from "react";
import bgImg from "../../../assets/homePageAssets/beOurPartner.png";
import { scrollSectionInView } from "../../../utils/functions";

const BeOurPartner = () => {
  return (
    <div className="p-6 md:p-12 rounded-3xl">
      <Box
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "60vh", md: "80vh" },
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          backgroundRepeat: "no-repeat",
          padding: "0 2rem",
          borderRadius: "24px",
        }}
      >
        <Box className="container mx-auto">
          <Box
            className="space-y-8"
            sx={{
              width: { xs: "100%", md: "60%" },
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              px: { xs: "0px", md: "28px" },
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontSize={"50px"}
              className="text-white"
              variant="h2"
              gutterBottom
            >
              Contact us for better <br className="hidden md:block" /> business
              opportunities
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              className="text-white"
              variant="body1"
            >
              Reach out to us to explore enhanced business prospects.{" "}
              <br className="hidden md:block" />
              Connect with our team for improved opportunities that{" "}
              <br className="hidden md:block" /> align with your goals.
            </Typography>

            <button
              onClick={() => scrollSectionInView("homepage_cta")}
              className="bg-white text-black p-2 rounded-lg border border-white hover:bg-transparent hover:text-white"
            >
              Let's Discuss
            </button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BeOurPartner;
