import React from "react";
import { AppGeneralLayout } from "../../../components";
import AboutContent from "./AboutContent";
import CallToAction from "./CallToAction";
import HeroSection from "./HeroSection";
import VisionAndMission from "./VisionAndMission";

const AboutUs = () => {
  return (
    <>
      <AppGeneralLayout>
        <HeroSection />
        <AboutContent />
        <VisionAndMission />
        <CallToAction />
        {/* <OurAchievements /> */}
        {/* <OurJourney /> */}
        {/* <Gallery /> */}
      </AppGeneralLayout>
    </>
  );
};

export default AboutUs;
