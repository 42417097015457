import hrideshVerma from "../../assets/ourTeamAssets/hrideshVerma.jpeg";
import jesalSharmaImg from "../../assets/ourTeamAssets/jesalSharmaImg.jpeg";

const vsTeamData = [
  {
    imgSrc: hrideshVerma,
    memberDesignation: "Principal Partner",
    memberName: "Hridesh Verma",
  },

  {
    imgSrc: jesalSharmaImg,
    memberDesignation: "Operations Manager",
    memberName: "Jesal Sharma",
  },
];
export default vsTeamData;
