import React, { useRef } from "react";
import BannerStructure from "./BannerStructure";
import Slider from "react-slick";
import { homePageBannerData } from "../../../data/constants/homePageBannerData";
import { SwitchSlides } from "../../../components";
import { scrollSectionInView } from "../../../utils/functions";

const HeroSection = () => {
  let settings = {
    // autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: true,
  };

  const sliderRef = useRef(null);

  const onNextClick = () => {
    sliderRef.current.slickNext();
  };

  const onPreviousClick = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="relative">
      <Slider ref={sliderRef} {...settings}>
        {homePageBannerData.map((bannerItem, index) => (
          <BannerStructure
            buttonOnClickHandler={() => scrollSectionInView("homepage_cta")}
            {...bannerItem}
            key={index}
          />
        ))}
      </Slider>

      <SwitchSlides
        theme="white"
        cardClassNames={
          "absolute left-[50%] translate-x-[-50%] bottom-[2%] md:bottom-[10%]"
        }
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </div>
  );
};

export default HeroSection;
