import React from "react";

const OurTeamMemberCard = ({
  imgSrc,
  memberName,
  memberDesignation,
  memberInfo,
  linkedInUrl,
}) => {
  return (
    <div className="relative h-[420px] w-[100%] rounded-2xl">
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-t from-[#000000b8] to-transparent rounded-3xl"></div>
      <img src={imgSrc} className="h-full w-full object-cover rounded-3xl" />
      <div className="text-white absolute bottom-0 p-8">
        <h4 className="font-semibold text-2xl sm:text-4xl my-2">
          {memberName}
        </h4>
        <p className="text-sm sm:text-xl">{memberDesignation}</p>
      </div>
    </div>
  );
};

export default OurTeamMemberCard;
