import { Typography } from "@mui/material";
import React from "react";
import VsServiceCard from "./VsServiceCard";
import { vsServicesData } from "../../../data/constants/vsServicesData";

const VSServices = () => {
  return (
    <div
      className="container mx-auto py-[75px] px-4 md:px-0"
      id="homepage_services"
    >
      <Typography
        className="text-black"
        variant="h3"
        fontFamily={"raleway"}
        gutterBottom
      >
        What we have to offer
      </Typography>

      <Typography
        className="text-black"
        variant="body2"
        letterSpacing={"1.2px"}
        sx={{ maxWidth: { xs: "100%", md: "35%" } }}
        fontFamily={"raleway"}
        style={{ paddingBottom: "40px" }}
      >
        Explore our array of available services. Uncover the diverse range of
        solutions we provide to meet your needs.
      </Typography>

      <div className="grid grid-cols-12 gap-7 md:gap-24">
        {vsServicesData.map((item, index) => (
          <div key={index} className="col-span-12 md:col-span-4">
            <VsServiceCard {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VSServices;
