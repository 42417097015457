import React from "react";
import { AppGeneralLayout } from "../../../components";
import "../styles/founder_journey.css";
import FoundersDetail from "./FoundersDetail";
import HeroSection from "./HeroSection";
import OurTeamMembers from "./OurTeamMembers";

const OurTeam = () => {
  return (
    <div>
      <AppGeneralLayout>
        <HeroSection />
        <FoundersDetail />
        <OurTeamMembers />
      </AppGeneralLayout>
    </div>
  );
};

export default OurTeam;
