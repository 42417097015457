import { Typography } from "@mui/material";
import React, { useState } from "react";
import EastIcon from "@mui/icons-material/East";
import { scrollSectionInView } from "../../../utils/functions";

const VsServiceCard = ({ title, content, img }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onClick={() => {
        scrollSectionInView("homepage_cta");
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="group relative space-y-8 p-7 bg-opacity-0 group-hover:bg-opacity-100 rounded-2xl min-h-full"
      style={{
        backgroundSize: "cover",
        backgroundImage: hovered && `url(${img})`,
      }}
    >
      <Typography
        lineHeight={"1.6"}
        fontFamily={"poppins"}
        letterSpacing={"1px"}
        className="text-[#777777] group-hover:text-white"
        variant="body1"
      >
        {title}
      </Typography>

      <Typography
        lineHeight={"1.6"}
        fontFamily={"poppins"}
        fontWeight={"900"}
        letterSpacing={"1px"}
        variant="h4"
        className="text-black group-hover:text-white font-extrabold"
      >
        {title}
      </Typography>

      <div className="w-full bg-black group-hover:bg-white h-2" />

      <Typography
        lineHeight={"1.6"}
        fontFamily={"poppins"}
        letterSpacing={"1px"}
        variant="subtitle1"
        className="text-black group-hover:text-white"
      >
        {content}
      </Typography>
      <EastIcon className="absolute right-4 bottom-4 text-black group-hover:text-white" />
    </div>
  );
};

export default VsServiceCard;
