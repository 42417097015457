import { ArrowRightAltRounded, CloseOutlined } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import webAppLogo from "../../../../assets/branding/appLogo.svg";
import handset_header_bg from "../../../../assets/homePageAssets/handset_header_bg.png";
import { appInternalRoutes } from "../../../../data/constants/appInternalRoutes";
import useScreenSize from "../../../../utils/hooks/useScreenSize";
import {
  CompanyMenuOption,
  InsightsMenuOption,
  ServicesMenuOption,
} from "./HeaderMenuOptions";

const Header = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const screenWidth = useScreenSize();
  const headerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setSelectedMenuItem("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="fixed left-0 right-0 z-50" ref={headerRef}>
        <div className="bg-white ">
          <div className="container mx-auto px-4 md:px-0">
            <div className="flex items-center justify-between py-4">
              <Link to={appInternalRoutes.HOME_PAGE}>
                <img src={webAppLogo} className="max-w-40 md:max-w-full" />
              </Link>

              {screenWidth < 1024 && !openMobileMenu && (
                <MenuIcon
                  onClick={() => {
                    setOpenMobileMenu(true);
                  }}
                />
              )}

              {screenWidth < 1024 && openMobileMenu && (
                <CloseOutlined
                  onClick={() => {
                    setOpenMobileMenu(false);
                    setSelectedMenuItem("");
                  }}
                />
              )}

              {screenWidth > 1024 && (
                <div
                  className={`items-center justify-between md:space-x-24 hidden md:flex`}
                >
                  <Typography
                    className={`cursor-pointer`}
                    sx={{ display: { xs: "none", md: "block" } }}
                    onClick={() => {
                      if (selectedMenuItem === "services") {
                        setSelectedMenuItem("");
                      } else setSelectedMenuItem("services");
                    }}
                    variant="body1"
                    fontWeight={"700"}
                  >
                    <span>Services</span>
                    <span>
                      <KeyboardArrowDownOutlinedIcon />
                    </span>
                  </Typography>

                  <Typography
                    className={`cursor-pointer`}
                    sx={{ display: { xs: "none", md: "block" } }}
                    onClick={() => {
                      if (selectedMenuItem === "company") {
                        setSelectedMenuItem("");
                      } else setSelectedMenuItem("company");
                    }}
                    fontWeight={"700"}
                    variant="body1"
                  >
                    <span>Company</span>
                    <span>
                      <KeyboardArrowDownOutlinedIcon />
                    </span>
                  </Typography>

                  {/* <Typography
                    className={`cursor-pointer`}
                    sx={{ display: { xs: "none", md: "block" } }}
                    onClick={() => {
                      if (selectedMenuItem === "insights") {
                        setSelectedMenuItem("");
                      } else setSelectedMenuItem("insights");
                    }}
                    fontWeight={"700"}
                    variant="body1"
                  >
                    <span>Insights</span>
                    <span>
                      <KeyboardArrowDownOutlinedIcon />
                    </span>
                  </Typography> */}
                </div>
              )}
            </div>
          </div>

          {screenWidth < 1024 && openMobileMenu && !selectedMenuItem && (
            <div className="h-screen overflow-scroll">
              <div className="relative">
                <img src={handset_header_bg} className="w-full" />

                <div className="absolute bottom-0 p-8 space-y-6">
                  <Typography
                    variant="h4"
                    color={"white"}
                    fontFamily={"raleway"}
                  >
                    Want to know more about us? Don’t worry, we got your back…
                  </Typography>

                  <span className="flex items-center space-x-4">
                    <Typography
                      variant="h5"
                      color={"white"}
                      fontFamily={"raleway"}
                    >
                      Click here to explore
                    </Typography>

                    <ArrowRightAltRounded className="text-white" />
                  </span>
                </div>
              </div>
              <div className="space-y-12 py-4">
                <span
                  className="flex items-center justify-between px-2"
                  onClick={() => {
                    if (selectedMenuItem === "services") {
                      setSelectedMenuItem("");
                    } else setSelectedMenuItem("services");
                  }}
                >
                  <Typography
                    py={"12px"}
                    px={"24px"}
                    fontFamily={"raleway"}
                    variant="h4"
                  >
                    Services
                  </Typography>

                  <ChevronRightIcon />
                </span>

                <span
                  onClick={() => {
                    if (selectedMenuItem === "company") {
                      setSelectedMenuItem("");
                    } else setSelectedMenuItem("company");
                  }}
                  className="flex items-center justify-between px-2 border-t border-t-[#f4f4f4] border-b border-b-[#f4f4f4]"
                >
                  <Typography
                    py={"12px"}
                    px={"24px"}
                    fontFamily={"raleway"}
                    variant="h4"
                  >
                    Company
                  </Typography>

                  <ChevronRightIcon />
                </span>

                <span
                  className="flex items-center justify-between px-2"
                  onClick={() => {
                    if (selectedMenuItem === "insights") {
                      setSelectedMenuItem("");
                    } else setSelectedMenuItem("insights");
                  }}
                >
                  <Typography
                    py={"12px"}
                    px={"24px"}
                    fontFamily={"raleway"}
                    variant="h4"
                  >
                    Insights
                  </Typography>

                  <ChevronRightIcon />
                </span>
              </div>
            </div>
          )}

          {selectedMenuItem === "services" ? (
            <ServicesMenuOption
              setSelectedMenuItem={setSelectedMenuItem}
              setOpenMobileMenu={setOpenMobileMenu}
            />
          ) : selectedMenuItem === "insights" ? (
            <InsightsMenuOption setSelectedMenuItem={setSelectedMenuItem} />
          ) : selectedMenuItem === "company" ? (
            <CompanyMenuOption setSelectedMenuItem={setSelectedMenuItem} />
          ) : (
            <></>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
