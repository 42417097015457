import { Typography } from "@mui/material";
import React from "react";

const FounderJourney = () => {
  return (
    <div>
      <div className="my-20 sm:my-36 px-6 sm:px-0">
        <div className="tracking-normal text-left sm:text-center mb-20">
          <h1 className="text-4xl sm:text-5xl font-light">
            Journey of a Master Mind
          </h1>
        </div>

        <ul className="OurTeamStyle_timeline_container__aLIdK space-y-8 md:space-y-32">
          <li className="OurTeamStyle_timeline_item__cqVaG">
            <Typography
              fontWeight={"400"}
              variant="h4"
              className="pb-4"
              color={"white"}
              fontFamily={"raleway"}
              fontSize={{ xs: "1rem", md: "2rem" }}
            >
              1998-2000: Completed MBA from Mumbai University
            </Typography>

            <Typography
              fontFamily={"poppins"}
              fontWeight={"200"}
              className="space-y-4"
              component={"div"}
            >
              <span className="inline-block">
                - Embarked on a journey of academic excellence, laying the
                foundation for future leadership roles.
              </span>
            </Typography>
          </li>
          <li className="OurTeamStyle_timeline_item__cqVaG">
            <Typography
              fontWeight={"400"}
              variant="h4"
              className="pb-4"
              color={"white"}
              fontFamily={"raleway"}
              fontSize={{ xs: "1rem", md: "2rem" }}
            >
              2000-2005: Gained Experience in Food and Banking Industry{" "}
            </Typography>

            <Typography
              fontFamily={"poppins"}
              fontWeight={"200"}
              className="space-y-4"
              component={"div"}
            >
              <span className="inline-block">
                - Initiated professional career at Nestle, honing skills in
                distribution management and performance evaluation.
              </span>

              <span className="inline-block">
                - Advanced to financial services, spearheading business growth
                strategies and dealer engagement.
              </span>
            </Typography>
          </li>
          <li className="OurTeamStyle_timeline_item__cqVaG">
            <Typography
              fontWeight={"400"}
              variant="h4"
              fontSize={{ xs: "1rem", md: "2rem" }}
              className="pb-4"
              color={"white"}
              fontFamily={"raleway"}
            >
              2006-2010 : Established a strong foundation in the education
              sector with notable accomplishments
            </Typography>

            <Typography
              fontFamily={"poppins"}
              fontWeight={"200"}
              className="space-y-4"
              component={"div"}
            >
              <span className="inline-block">
                - Transitioned to insurance, optimizing customer experience and
                expanding distribution networks.
              </span>
            </Typography>
          </li>
          <li className="OurTeamStyle_timeline_item__cqVaG">
            <Typography
              fontWeight={"400"}
              variant="h4"
              className="pb-4"
              fontSize={{ xs: "1rem", md: "2rem" }}
              color={"white"}
              fontFamily={"raleway"}
            >
              2017-2024: Career Transition and Growth in Canada
            </Typography>
            <Typography
              fontFamily={"poppins"}
              fontWeight={"200"}
              className="space-y-4"
              component={"div"}
            >
              <span className="inline-block">
                - Immigrated to Canada, joining IMG Import as National Sales
                Manager and then Save Max International as VP Global Franchise
                Operations.
              </span>

              <span className="inline-block">
                - Led diverse teams, developed marketing strategies, explored
                new territories, and achieved revenue targets.
              </span>
              <span className="inline-block">
                - Enhanced skills in international business, strategic planning,
                P&L management, and global market expansion.
              </span>

              <span className="inline-block">
                - Served as the CEO at AN Global Consulting Canada, driving
                business migration, consulting, and growth strategies in the
                international market in year 2023
              </span>
            </Typography>
          </li>

          <li className="OurTeamStyle_timeline_item__cqVaG">
            <Typography
              fontWeight={"400"}
              variant="h4"
              className="pb-4"
              color={"white"}
              fontFamily={"raleway"}
              fontSize={{ xs: "1rem", md: "2rem" }}
            >
              2024: Vision Stream Global Consultancy (Founding Year)
            </Typography>

            <Typography
              fontFamily={"poppins"}
              fontWeight={"200"}
              className="space-y-4"
              component={"div"}
            >
              <span className="inline-block">
                - Founded Vision Stream Global Consultancy, specializing in
                business expansion, business migration, and franchise
                development.
              </span>

              <span className="inline-block">
                - Leads the company with expertise gained from extensive
                experience in international markets and strategic leadership
                roles.
              </span>
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FounderJourney;
