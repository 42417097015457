import React from "react";
import ReactDOM from "react-dom/client";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import App from "./App";
import avatarImg from "./assets/illustrations/chatBotAvtar.jpeg";
import { vsServicesData } from "./data/constants/vsServicesData";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

// all available props
const theme = {
  background: "#f5f8fb",
  fontFamily: "Poppins",
  headerBgColor: "#000",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#fff",
  botFontColor: "#000",
  userBubbleColor: "#000",
  userFontColor: "#000",
};

root.render(
  <>
    <ThemeProvider theme={theme}>
      <App />
      <ChatBot
        botAvatar={avatarImg}
        handleEnd={({ steps, values }) => {
          window.open(
            `https://wa.me/+14163338747?text= I am in interested in knowing more about ${
              vsServicesData.find((service) => service.id === values[0]).title
            }.`,
            "_blank"
          );
        }}
        floating
        steps={[
          {
            id: "1",
            message:
              "Hey , thank you for contacting Vision Stream's live chat!",
            trigger: "2",
          },
          {
            id: "2",
            message: "How can we help you today?",
            trigger: "3",
          },
          {
            id: "3",
            end: true,
            options: vsServicesData.map((service) => ({
              label: service.title,
              value: service.id,
            })),
          },
        ]}
      />
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
