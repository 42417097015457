import React from "react";
import { Box, Typography } from "@mui/material";

const BannerStructure = ({
  title,
  description,
  buttonText,
  bgImg,
  buttonOnClickHandler,
}) => {
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 2rem",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            background: "#000000",
            opacity: "0.7",
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "100vw",
            left: 0,
            right: 0,
            bottom: "2%",
            zIndex: "0",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: "-1",
            height: "100%",
          }}
        >
          <video
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            controls={false}
            muted
            loop
            playsInline
            autoPlay={true}
          >
            <source defer src={bgImg} type="video/mp4" />
          </video>
        </Box>
        <Box className="container mx-auto z-50 flex items-center justify-center">
          <Box
            width={{ xs: "100%", md: "60%" }}
            className="space-y-8"
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              fontFamily={"raleway"}
              fontWeight={"900"}
              fontSize={{ xs: "50px", md: "70px" }}
              className="text-white"
              variant="h2"
              textAlign={"center"}
              gutterBottom
            >
              {title}
            </Typography>
            <Typography
              lineHeight={"1.6"}
              fontFamily={"poppins"}
              letterSpacing={"1px"}
              className="text-white"
              textAlign={"center"}
              variant="body1"
            >
              {description}
            </Typography>

            <button
              onClick={buttonOnClickHandler}
              className="bg-white text-black p-2 rounded-lg border border-white hover:bg-transparent hover:text-white mx-auto md:mx-0"
            >
              {buttonText}
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BannerStructure;
