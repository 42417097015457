import React from "react";
import vsTeamData from "../../../data/constants/vsTeamData";
import OurTeamMemberCard from "./OurTeamMemberCard";

const OurTeamMembers = () => {
  return (
    <>
      <div className="mb-20 sm:mb-36 mx-12 lg:mx-36 px-6 sm:px-0">
        <h1 className="text-4xl sm:text-5xl font-light">
          The success you see, the faces you don’t!
        </h1>

        <p className="font-light text-lg mt-5 mb-12">
          Our organization is run by some extremely creative, talented, and
          <br className="hidden md:block" /> skilled individuals. Have a look
          and see for yourself!
        </p>

        <div className="grid grid-cols-12 gap-6">
          {vsTeamData.map((member, index) => (
            <div
              key={index}
              className="col-span-12 md:col-span-6 lg:col-span-4"
            >
              <OurTeamMemberCard {...member} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OurTeamMembers;
