import React from "react";
import { AppGeneralLayout } from "../../../components";
import CalanderSection from "./CalanderSection";
import ContactUsForm from "./ContactForm";
import Location from "./Location";
import PageHeroSection from "./PageHeroSection";

const ContactUs = () => {
  return (
    <div>
      <AppGeneralLayout>
        <PageHeroSection />
        <CalanderSection />
        {/* <BusinessTimingSection /> */}
        <Location />
        <ContactUsForm />
      </AppGeneralLayout>
    </div>
  );
};

export default ContactUs;
